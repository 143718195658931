<template>
	<div>
		<div class="person_infor">
			<div class="person_infor_left">
				<div class="login_top">
					<div class="img"> <img src="@/assets/login/flag.png"></div>
					<div class="login_title"> {{$t('set.adviceFeedback')}}</div>
				</div>
			</div>
			<div class="person_infor_right" @click="toFeedbackRecord">
				{{$t('title.feedbackRecord')}}
			</div>
		</div>
		<div class="advice">
			<div class="advice_title">
				{{$t('set.feedbackType')}}
			</div>
			<div class="advice_type">
				<div :class="['advice_type_item',curIndex==index?'select':'un_select']" v-for="(item,index) in typeArr"
					@click="typeItemClick(index)">
					{{item.title}}
				</div>
			</div>
			<div class="input_textarea">
				<el-input type="textarea" v-model="body" :autosize="true"
					:placeholder="$t('set.feedbackContentTextarea')">
				</el-input>
			</div>

			<div class="all_imgs">
				<el-upload ref="uploadImg" :action="uploadImgUrl" list-type="picture-card"
					:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :before-upload="beforeUpload"
					:on-change="onChange" :limit="6" :multiple="true">
					<i class="el-icon-plus"></i>
					<div slot="tip" class="el-upload__tip">{{$t('pleaseUploadPhoto')}}</div>
				</el-upload>
				<el-dialog :visible.sync="previewImgDialog" :modal="false">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
			</div>
			<div class="advice_title">
				{{$t('set.leaveYourContactInformation')}}
			</div>
			<div class="input_content">
				<el-input type="" :placeholder="$t('set.phoneAndQQAndEmail')" v-model="contact" />
			</div>
			<div class="btn" @click="submitSuggestion">
				{{$t('submit')}}
			</div>
		</div>

	</div>
</template>

<script>
	import {
		uploadImgUrl
	} from '@/common/utils/config.js';


	export default {
		data() {
			return {
				uploadImgUrl: uploadImgUrl,


				dialogImageUrl: '',
				previewImgDialog: false,

				body: '',
				contact: '',
				typeArr: [], //反馈类型
				curIndex: 0,
				img_list: [], //上传的图片列表
			}
		},
		mounted() {
			this.suggestionsType();
		},
		methods: {
			suggestionsType() {
				this.$http.allRecord({
					// type: 5
				}).then(res => {
					if (res.code == 1) {
						this.typeArr = res.data.types;
					}
				})
			},
			typeItemClick(index) {
				this.curIndex = index;
			},

			// 图片删除
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			// 图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.previewImgDialog = true;
			},
			// 上传之前
			beforeUpload(file) {
				const fileName = file.name
				const fileType = fileName.substring(fileName.lastIndexOf('.'));
				console.log("文件类型", fileType);

				if (
					fileType === '.jpg' ||
					fileType === '.png' ||
					fileType === '.jpeg' ||
					fileType === '.bmp' ||
					fileType === '.gif'
				) {

				} else {
					this.$message.error(
						'不是,jpeg,.png,.jpg,.bmp,.gif,.mp4,.avi,.rm,.ram,.wmv文件,请上传正确的图片类型'
					)
					return false;
				}
			},

			// 上传图片成功
			onChange(file, fileList) {
				console.log(file);
				console.log(fileList);
				this.img_list = fileList;
			},

			// 去反馈记录
			toFeedbackRecord() {
				this.$router.push('/feedbackRecord')
			},
			// 提交反馈记录
			submitSuggestion() {
				if (this.flag) {
					return;
				}

				if (!this.body) {
					this.$message.error(this.$t('set.feedbackContentCannotEmpty'));
					return;
				}

				let newImgList = this.img_list.map(item => item.response.data[0].id).join(',');

				if (newImgList.length == 0) {
					this.$message.error(this.$t('tips.atLeastOnePhoto'));
					return;
				}

				if (!this.contact) {
					this.$message.error(this.$t('set.leaveYourContactInformation'));
					return;
				}
				let phone=/^1[3-9]\d{9}$/;
				if(!phone.test(this.contact)){
					 this.$message.error(this.$t('set.phoneText'));
					 return false;
				}
				let type = this.typeArr[this.curIndex].id;
				this.flag = true;
				let data = {
					type,
					body: this.body,
					thumb: newImgList,
					contact: this.contact,
				}

				this.$http.addSuggestion(data).then(res => {
					this.flag = false;
					if (res.code == 1) {
						this.$message.success(res.msg);
						setTimeout(() => {
							this.toFeedbackRecord();
						}, 2000)
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		box-sizing: border-box;
		width: 926px;
		display: flex;
		justify-content: space-between;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;
	}

	.person_infor_left {
		.login_top {
			display: flex;


			.img {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: 18px;
				}
			}

			.login_title {
				margin-left: 8px;
				font-weight: bold;
				font-size: 18px;
			}
		}
	}

	.person_infor_right {
		color: #999999;
		font-size: 14px;

		&:hover {
			cursor: pointer;
		}
	}

	.advice {
		padding: 32px 32px 45px;
		text-align: left;

		.advice_title {
			text-align: left;
			font-weight: bold;
			color: #333333;
			font-size: 18px;
		}

		.advice_type {
			margin-top: 24px;
			display: flex;
			flex-wrap: wrap;
			max-width: 900px;

			.advice_type_item {
				margin-right: 24px;
				margin-bottom: 24px;
				// width: 140px;
				padding: 0 24px;
				height: 40px;
				line-height: 40px;
				opacity: 1;
				border-radius: 4px;

				&:hover {
					cursor: pointer;
				}
			}

			.select {
				color: #FFFFFF;
				background: #E60012;
				border: 1px solid #DDDDDD;
			}

			.un_select {
				color: #333333;
				background: #FFFFFF;
				border: 1px solid #DDDDDD;
			}
		}
	}

	.input_textarea {
		width: 800px;
		background: #F6F7F9;
		opacity: 1;
		border-radius: 4px;
		min-height: 200px;
		padding: 16px;

		/deep/ .el-textarea {
			textarea {
				outline: none;
				background: #F6F7F9;
				border: none;
				font-size: 14px;
				resize: none;
				font-family: Microsoft YaHei;
			}
		}
	}

	.all_imgs {
		text-align: left;
		margin: 32px 0 32px;
		padding-bottom: 32px;
		border-bottom: 1px dashed #EEEEEE;
	}

	// /deep/ .el-upload--picture-card {
	// 	width: 180px;
	// 	height: 180px;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// }

	// /deep/ .el-upload__tip {
	// 	width: 180px;
	// 	text-align: center;
	// }

	.input_content {
		width: 540px;
		display: inline-block;
		border-radius: 4px;
		margin: 24px 0 56px;

		input {
			border: none;
			outline: none;
		}
	}

	.btn {
		width: 216px;
		text-align: center;
		height: 48px;
		line-height: 48px;
		background: #E60012;
		opacity: 1;
		border-radius: 24px;
		font-size: 16px;
		color: #FFFFFF;

		&:hover {
			cursor: pointer;
		}
	}
</style>
